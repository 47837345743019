import { IEnvironment } from '@Environments/interface';

export const environment: IEnvironment = {
  production: false,
  name: 'uat',
  apigeeUrl: 'https://uat.api-wr.com',
  oapApiClientId: 'ALGOcG28BRUHAhBKsure4o0cbGGcBASX', // OAP Test
  cslApiUrl: 'https://uat-api.woolworthsrewards.com.au/wx/v1/csl',
  cdnContentfulApi: {
    url: 'https://cdn.contentful.com/spaces/45lly9oa9o4c/environments/staging',
    token: '7dHWMSpmjl7yxKrDSsZwdpK6VnTC3B1-6PMvFea5NNQ',
    previewUrl: 'https://preview.contentful.com/spaces/45lly9oa9o4c/environments/staging',
    previewToken: 'fLpNmZW2RxqNDWELDJCWgNZQrZad0eaMNowdh_l4JIQ',
  },
  renderingLayer: 'https://activate-test.woolworthsrewards.com.au/rendering-layer/index.html',
  iframeLoginUrl: 'https://test-accounts.woolworthsrewards.com.au/secure/login.html',
  iframeLoginStyleUrl: 'https://activate-test.woolworthsrewards.com.au/assets/styles/dpu-login.css',
  tealium: {
    commIdPageName: 'ww-rw:offers:c',
    offerIdPageName: 'ww-rw:offers:o',
    promoPageName: 'ww-rw:offers:p',
    script: 'https://tags.tiqcdn.com/utag/woolworths/rewards/dev/utag.js',
  },
  adobe: {
    orgID: '4353388057AC8D357F000101@AdobeOrg',
  },
  swrve: {
    appId: 31941,
    apiKey: 'web_sdk-evIsEWLCg93I8KRPjFw2',
  },
  previewCms: {
    script: 'https://contentful-previews.wowmp.com.au/test/apps/preview-widget/preview-widget.bundled.js',
  },
  dynatrace: {
    script: '',
  },
};
